import { useState } from "react"

import { Tooltip } from "@material-ui/core"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { IdWrapper } from "src/components/Paradise/sharedStyles"
import { useFetchOrganizations } from "src/data/organizations/queries/organizationQueries"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { colorsLegacy } from "src/ui/colors"
import InfoIcon from "src/ui/icons/info.svg"
import { InternalLink } from "src/ui/Link/InternalLink"

const LIMIT = 15

export function ParadisUserOrganizations({ userId }: { userId: string }) {
  const { navigate } = useRouter()

  const [offset, setOffset] = useState(0)

  const fetchedOrgs = useFetchOrganizations({
    params: {
      membership_user_id: userId,
      offset,
      limit: LIMIT,
    },
  })

  const header = [
    <div key={"org-id"}>Org id</div>,
    <div key={"name"}>Name</div>,
    <div key={"role"}>Role</div>,
  ]

  const rows =
    fetchedOrgs.data?.organizations.map((org) => (
      <>
        <div>
          <IdWrapper>
            <InternalLink
              to={Routes.ParadiseOrganization.location(org.id)}
              onClick={(e) => e.stopPropagation()}
            >
              {org.id}
            </InternalLink>
            {org.deleted && (
              <Tooltip title="Deleted">
                <InfoIcon
                  width={12}
                  fill={colorsLegacy.systemEmergencyForeground}
                />
              </Tooltip>
            )}
          </IdWrapper>
        </div>
        <div>{org.name}</div>
        <div>{org.user_role}</div>
      </>
    )) ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Organizations" />
      <ParadiseTable
        header={header}
        rows={rows}
        templateColumns="min-content min-content auto"
        error={{
          hasError: fetchedOrgs.isError,
          title: fetchedOrgs.error?.message,
        }}
        onRowClick={(index) => {
          if (fetchedOrgs.data?.organizations) {
            navigate(
              Routes.ParadiseOrganization.location(
                fetchedOrgs.data.organizations[index].id
              )
            )
          }
        }}
      />
      <ParadisePager
        limit={LIMIT}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchedOrgs.data?.paging.total_count}
      />
    </div>
  )
}
