import { useState } from "react"
import styled from "styled-components"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseDevicesTable } from "src/components/Paradise/ParadiseDevicesTable"
import { ParadiseUserDevicesFilters } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserDevices/ParadiseUserDevicesFilters"
import { useFetchParadiseDevices } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { useSearchParams } from "src/router/useSearchParams"
import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const LIMIT = 15

export function ParadiseUserDevices({ userId }: { userId: string }) {
  const [offset, setOffset] = useState(0)
  const { searchParams, setSearchParams } = useSearchParams({
    keys: [
      {
        key: "devices_description",
        type: "string",
      },
      {
        key: "devices_hardwareVersion",
        type: "number",
      },
    ],
  })

  const fetchedDevices = useFetchParadiseDevices({
    filter: {
      user_id: userId,
      limit: LIMIT,
      description: searchParams.devices_description ?? undefined,
      offset,
      include_inactive: true,
      hardware_version: searchParams.devices_hardwareVersion ?? undefined,
    },
  })
  const devices = fetchedDevices.data?.devices ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Devices" />
      <ParadiseUserDevicesFilters
        filter={searchParams}
        setFilter={setSearchParams}
      />

      <OverviewWrapper>
        <Overview>
          <MText variant="nano">Total devices</MText>
          <MText variant="subtitleS">
            {fetchedDevices.data?.paging?.total_count}
          </MText>
        </Overview>
      </OverviewWrapper>
      <ParadiseDevicesTable
        devices={devices}
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        totalCount={fetchedDevices.data?.paging?.total_count}
        error={{
          hasError: fetchedDevices.isError,
          title: fetchedDevices.error?.message,
        }}
      />
    </div>
  )
}

const OverviewWrapper = styled.div`
  display: flex;
  gap: ${spacing.S};
  margin-top: ${spacing.S};
`

const Overview = styled.div`
  padding: ${spacing.XS};
  background-color: ${mColors.neutral};
  border-radius: 0.5rem;
`
