import styled from "styled-components"

import { TextField } from "@material-ui/core"

import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import { useFetchParadiseHardwareVersions } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { TSetSearchParamsProp } from "src/router/useSearchParams"
import { DropdownSelect } from "src/ui/DropdownSelect/DropdownSelect"
import { Filter } from "src/ui/Filter/Filter"
import { spacing } from "src/ui/spacing"

export type TParadiseUserDevicesFilters = {
  devices_description: string | null
  devices_hardwareVersion: number | null
}

export function ParadiseUserDevicesFilters({
  filter,
  setFilter,
}: {
  filter: TParadiseUserDevicesFilters
  setFilter: TSetSearchParamsProp<TParadiseUserDevicesFilters>
}) {
  const fetchHardwareVersions = useFetchParadiseHardwareVersions()
  const hardwareVersions = fetchHardwareVersions.data

  const selectedHardwareVersion = hardwareVersions?.find(
    (hw) => hw.version_number === filter.devices_hardwareVersion
  )
  return (
    <div>
      <FilterWrapper>
        <Filter
          filters={[
            {
              id: "description",
              label: "Description",
              value: filter.devices_description ?? "",
              active: !!filter.devices_description,
              alwaysVisible: true,
              render: (
                <FilterContent>
                  <TextField
                    fullWidth
                    placeholder="Description"
                    value={filter.devices_description}
                    onChange={(e) =>
                      setFilter("devices_description", e.target.value)
                    }
                  />
                </FilterContent>
              ),
              onRemove: () => setFilter("devices_description", ""),
              removeLabel: "Reset",
            },
            {
              id: "hardware_version",
              label: "Hardware version",
              value: selectedHardwareVersion?.name ?? "",
              active: !!filter.devices_hardwareVersion,
              alwaysVisible: true,
              render: (
                <FilterContent>
                  {hardwareVersions && (
                    <DropdownSelect
                      label={
                        filter.devices_hardwareVersion
                          ? getDeviceHardwareType(
                              filter.devices_hardwareVersion
                            ).type
                          : "Hardware version"
                      }
                      options={hardwareVersions.map((version) => ({
                        label: `${version.name} (${version.version_number})`,
                        value: version.name,
                        version_number: version.version_number,
                      }))}
                      selectedValue={
                        filter.devices_hardwareVersion?.toString() ?? null
                      }
                      onChange={({ option }) =>
                        setFilter(
                          "devices_hardwareVersion",
                          option.version_number
                        )
                      }
                    />
                  )}
                </FilterContent>
              ),
              onRemove: () => setFilter("devices_hardwareVersion", null),
              removeLabel: "Reset",
            },
          ]}
        />
      </FilterWrapper>
    </div>
  )
}

const FilterWrapper = styled.div`
  display: block;
  margin-top: ${spacing.M};
`

const FilterContent = styled.div`
  padding: ${spacing.XS};
`
